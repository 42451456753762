import Vue from "vue";
import VueRouter from "vue-router";
import Start from "../views/Start.vue";
import Basics from "../views/Basics.vue";
import About from "../views/About.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
  },
  {
    path: "/grundsaetze",
    name: "Die Ethischen Grundsätze",
    component: Basics,
  },
  {
    path: "/ueber-uns",
    name: "Über die App",
    component: About,
  },
  {
    path: "/fragenkatalog",
    name: "Fragenkatalog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "questions" */ "../views/Questionnaire.vue"),
  },
  {
    path: "/fragenkatalog/:id-:slug",
    name: "questionCategory",
    component: () => import("../views/Category.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
