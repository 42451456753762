<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Pferdinand</v-list-item-title>
          <v-list-item-subtitle> Tierisch gut lernen!</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>

          <v-list-group v-if="item.listCategories">
            <v-divider></v-divider>
            <v-list-item
              v-for="category in categories"
              :key="category.id"
              :to="'/fragenkatalog/' + category.id + '-' + category.slug"
              :category="category"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ category.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      prominent
      src="img/lone-rider.jpg"
      shrink-on-scroll
    >
      <template v-slot:img="{ props }">
        <v-img
          id="hero"
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.3), rgba(25,32,72,.3)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import categoriesData from "../public/data/categories.json";
export default {
  data: () => ({
    drawer: null,
    items: [
      { title: "Start", icon: "mdi-home", to: "/" },
      {
        title: "Die Ethischen Grundsätze",
        icon: "mdi-horse-human",
        to: "/grundsaetze",
      },
      {
        title: "Fragenkatalog",
        icon: "mdi-message-question",
        to: "/fragenkatalog",
        listCategories: true,
      },
      { title: "Über die App", icon: "mdi-information", to: "/ueber-uns" },
    ],
    categories: categoriesData,
  }),
};
</script>

<style lang="scss">
#hero .v-image__image {
  background-position-y: 57% !important;
}
</style>
